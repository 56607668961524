<template>
  <el-cascader
    class="project-tree-select"
    collapse-tags
    :options="projectTree"
    v-model="project_id"
    :props="{ value: 'id', label: 'name', multiple: all }"
    clearable
    filterable
    ref="demoCascader"
    size="small"
    :placeholder="project_name"
    :show-all-levels="showAllLevels"
    @change="onInput"
  ></el-cascader>
</template>
<script>
// import TreeSelect from "@riophae/vue-treeselect";
export default {
  name: "project-tree-select",
  components: {
    // TreeSelect,
  },
  props: {
    value: {
      type: [String, Number, Array],
    },
    // 查询诊所设置的项目
    clinicId: {
      type: [String, Number],
    },
    factoryId: {
      type: [String, Number],
    },
    factory: Boolean,
    all: Boolean,
    showAllLevels: {
      type: Boolean,
      default: true,
    },
    projectName: [String, Number],
    ifDisabled: Boolean, // 是否需要禁用项目
  },
  data() {
    return {
      projectTree: [], // 包括全部分类和项目
      project_id: [],
      project_name: localStorage.lang_data == 1 ? "请选择" : "Please Choose",
    };
  },
  created() {},
  mounted() {
    this.getProjectTreeData();
  },
  methods: {
    localSearchFunc(filterValue, node, matchKey) {
      return (
        node[matchKey].includes(filterValue) ||
        node.raw.name_initial.includes(filterValue)
      );
    },
    async getProjectTreeData() {
      let factory_id = "";
      if (this.factory) {
        factory_id = this.factoryId ? this.factoryId : "";
      }
      let data = {
        factory_id: factory_id,
        clinic_id: this.clinicId,
        status_type: this.ifDisabled ? 1 : -1,
      };
      let res = await this.$axios.post("/setting/factory/getProjectTree", data);
      if (res.code == 1) {
        // this.projectTree = this.$util.arrayToTree(res.data.list, 0, "parent_id");
        this.projectTree = res.data.list;
      }
    },
    onInput(arr) {
      const obj =
        this.$refs["demoCascader"].getCheckedNodes()[0] ||
        this.$refs["demoCascader"].panel.getNodeByValue(arr);

      if (arr.length > 0) {
        if (this.all) {
          let list = [];
          arr.forEach((item) => {
            list.push(item[item.length - 1]);
          });
          this.$emit("change", list, obj.data);
          this.$emit("input", list, obj.data);
        } else {
          this.$emit("change", arr[arr.length - 1], obj.data);
          this.$emit("input", arr[arr.length - 1], obj.data);
        }
      } else {
        this.$emit("change", null);
      }
    },
  },
  watch: {
    value(val) {
      if (val.length < 1) {
        this.project_name =
          localStorage.lang_data == 1 ? "请选择" : "Please Choose";
        this.project_id = [];
      }
    },
    factoryId() {
      this.getProjectTreeData();
    },
    projectName(val) {
      this.project_name = val;
    },
  },
};
</script>
<style lang="scss">
.project-tree-select {
  .el-input--small .el-input__inner {
    height: 32px !important;
  }
  .el-cascader__tags .el-tag {
    margin: 4px 0 2px 6px !important;
  }
  .el-cascader__tags .el-tag {
    max-width: 50% !important;
  }
  .el-cascader__search-input {
    flex: none;
    width: 80px;
    // border:1px solid red;
  }
}
</style>
