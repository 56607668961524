<!-- 医生管理员医生选择框(当前管理员所属诊所的所有医生) -->
<template>
  <el-select v-model="val" size="mini" @change="onChange">
    <el-option
      v-for="item in list"
      :key="item.doctor_id"
      :value="item.doctor_id"
      :label="item.true_name"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  props: {
    value: {
      type: [Number, String],
    },
    clinicId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      val: "",
      list: [], // 列表
    };
  },
  mounted() {
    this.val = this.value;
    this.getList();
  },
  methods: {
    async getList() {
      if (!this.clinicId) {
        console.log("管理员医生列表初始化失败，获取诊所失败");
        return;
      }
      let params = {
        admin_clinic_id: this.clinicId,
      };
      let res = await this.$axios.post("/ucenter/getclinicdoctorlist", params);
      if (res.code == 1) {
        this.list = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    onChange(val) {
      let list = this.list.filter((item) => item.doctor_id == val);
      let rowData = list.length > 0 ? list[0] : null;
      this.$emit("input", val);
      this.$emit("change", val, rowData);
    },
  },
  watch: {
    value() {
      this.val = this.value;
    },
    clinicId() {
      this.getList();
    },
  },
};
</script>