<template>
  <div class="createOrder">
    <div
      class="content"
      v-loading="loading"
      :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
    >
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mt20 mb20">
        <el-breadcrumb-item :to="{ path: '/home/' }">
          <span>{{ lang_data == 1 ? "订单管理" : "Order Management" }}</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <span v-if="!isEdit">{{
            lang_data == 1 ? "新建订单" : "New Order"
          }}</span>
          <span v-else>{{ lang_data == 1 ? "编辑订单" : "Edit Order" }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <div class="progress" v-if="btnLoading">
        <div class="mb10" style="font-size: 15px">
          <b>图片文件上传中 . . . </b>
        </div>
        <div class="progressCon">
          <div v-for="(item, i) in progressList" :key="i">
            <div v-if="item.name">
              <div class="progressName">
                <b>{{ item.name }}</b>
              </div>
              <el-progress :percentage="item.progressVal"></el-progress>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          box-shadow: 0 0 12px 0 #e4e4e4;
          border-radius: 8px;
          border: 1px solid #e4e4e4;
          overflow: hidden;
        "
      >
        <div class="topbar" :class="{ posFix: scrollTop > 80 }">
          <span class="subtitle">
            <span class="txt">
              <span v-if="isEdit"
                >{{ lang_data == 1 ? "编辑订单" : "Edit Order" }}
              </span>
              <span v-else>{{
                lang_data == 1 ? "新建订单" : "New Order"
              }}</span>
            </span>
          </span>
          <div>
            <el-button round @click="$router.back()" size="medium">{{
              lang_data == 1 ? "返回" : "Back"
            }}</el-button>
            <el-button
              type="primary"
              round
              v-if="is_tmp == 1"
              @click="onConfirm(1)"
              :loading="btnLoading"
              size="medium"
            >
              {{ lang_data == 1 ? "暂存" : "Save" }}
            </el-button>
            <el-button
              @click="onConfirm"
              size="medium"
              type="primary"
              round
              :loading="btnLoading"
              >{{ lang_data == 1 ? "提交" : "Submit" }}</el-button
            >
          </div>
        </div>
        <div class="mainform" :class="{ scrollClass: scrollTop > 80 }">
          <el-form
            ref="form"
            :model="form"
            label-width="115px"
            label-position="left"
            :rules="rules"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="lang_data == 1 ? '临时号' : 'temporarily number'"
                  prop="temporary_no"
                >
                  <el-input
                    style="width: 280px"
                    v-model="form.temporary_no"
                    size="mini"
                    :placeholder="
                      lang_data == 1 ? '示例 mm00000000' : 'Please Enter'
                    "
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="lang_data == 1 ? '诊所' : 'Client'"
                  prop="clinic_id"
                >
                  <ClinicSelect
                    v-if="loaded"
                    style="width: 280px"
                    v-model="form.clinic_id"
                    @onInit="onClinicInit"
                    :placeholder="lang_data == 1 ? '请输入' : 'Please Enter'"
                    @change="onClinicChange"
                    :is-all="false"
                  ></ClinicSelect>
                </el-form-item>
                <el-form-item
                  :label="lang_data == 1 ? '医生' : 'Doctor'"
                  prop="doctor_id"
                >
                  <!-- 如果是诊所管理员获取诊所下的所有医生，且可选择任何一个医生 否则显示当前登录的医生 -->
                  <AdminDoctorSelect
                    style="width: 280px"
                    v-model="form.doctor_id"
                    :clinicId="form.clinic_id"
                    :placeholder="lang_data == 1 ? '请输入' : 'Please Enter'"
                    v-if="form.is_admin > 0"
                    @change="onAdminDoctorChange"
                  ></AdminDoctorSelect>
                  <span v-else>{{ doctorInfo.true_name }}</span>
                </el-form-item>
                <el-form-item
                  :label="lang_data == 1 ? '患者姓名' : 'Case Name'"
                  prop="patient_name"
                >
                  <el-input
                    style="width: 280px"
                    v-model="form.patient_name"
                    size="mini"
                    :placeholder="lang_data == 1 ? '请输入' : 'Please Enter'"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="lang_data == 1 ? '年龄' : 'Age'"
                  prop="patient_age"
                >
                  <el-input
                    style="width: 280px"
                    v-model.number="form.patient_age"
                    size="mini"
                    :placeholder="
                      lang_data == 1 ? '请输入患者年龄' : 'Please Enter'
                    "
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="lang_data == 1 ? '性别' : 'Gender'"
                  prop="patient_gender"
                >
                  <el-select
                    v-model="form.patient_gender"
                    size="mini"
                    style="width: 280px"
                    :placeholder="
                      lang_data == 1 ? '请选择性别' : 'Please select'
                    "
                  >
                    <el-option
                      value="1"
                      :label="lang_data == 1 ? '男' : 'Female'"
                    ></el-option>
                    <el-option
                      value="2"
                      :label="lang_data == 1 ? '女' : 'Male'"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  :label="
                    lang_data == 1 ? '承诺出货时间' : 'Committed shipping time'
                  "
                  v-if="form.factory_id == 7"
                >
                  <el-date-picker
                    v-model="form.express_promise_time"
                    style="width: 280px"
                    type="date"
                    size="mini"
                    :picker-options="expireTimeOption"
                    value-format="timestamp"
                    :placeholder="
                      lang_data == 1 ? '请选择时间' : 'Please select'
                    "
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="lang_data == 1 ? '工厂' : 'Factory'"
                  prop="factory_id"
                >
                  <FactorySelect
                    style="width: 280px"
                    v-model="form.factory_id"
                    :clinicId="form.clinic_id"
                    @change="onFactoryChange"
                    @defaultChang="defaultChang"
                    :is-all="false"
                  ></FactorySelect>
                </el-form-item>

                <el-form-item :label="lang_data == 1 ? '联系方式' : 'Phone'">
                  {{ doctorInfo.mobile }}
                  <!-- {{ form.doctor_mobile }} -->
                </el-form-item>

                <el-form-item
                  :label="lang_data == 1 ? '颈部牙齿颜色' : 'Gingival'"
                  prop="up_color_id"
                >
                  <ToothColorSelect
                    :factoryId="form.factory_id"
                    v-model="form.up_color_id"
                    :is-all="false"
                    style="width: 140px"
                  ></ToothColorSelect>
                  <el-input
                    v-if="form.up_color_id == -1"
                    size="mini"
                    v-model="form.up_color_name"
                    :placeholder="lang_data == 1 ? '请输入' : 'Please Enter'"
                    clearable
                    style="width: 140px"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  prop="color_id"
                  :label="lang_data == 1 ? '体部牙齿颜色' : 'Body'"
                >
                  <ToothColorSelect
                    :factoryId="form.factory_id"
                    v-model="form.color_id"
                    :is-all="false"
                    style="width: 140px"
                  ></ToothColorSelect>
                  <el-input
                    v-if="form.color_id == -1"
                    size="mini"
                    v-model="form.color_name"
                    :placeholder="lang_data == 1 ? '请输入' : 'Please Enter'"
                    clearable
                    style="width: 140px"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  prop="low_color_id"
                  :label="lang_data == 1 ? '切端牙齿颜色' : 'Incisal'"
                >
                  <ToothColorSelect
                    :factoryId="form.factory_id"
                    v-model="form.low_color_id"
                    :is-all="false"
                    style="width: 140px"
                  ></ToothColorSelect>
                  <el-input
                    v-if="form.low_color_id == -1"
                    size="mini"
                    v-model="form.low_color_name"
                    :placeholder="lang_data == 1 ? '请输入' : 'Please Enter'"
                    clearable
                    style="width: 140px"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  prop="butment_color_id"
                  :label="lang_data == 1 ? '基牙颜色' : 'Stump'"
                >
                  <ToothColorSelect
                    :factoryId="form.factory_id"
                    v-model="form.butment_color_id"
                    :is-all="false"
                    style="width: 140px"
                  ></ToothColorSelect>
                  <el-input
                    v-if="form.butment_color_id == -1"
                    size="mini"
                    v-model="form.butment_color_name"
                    :placeholder="lang_data == 1 ? '请输入' : 'Please Enter'"
                    clearable
                    style="width: 140px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <div class="mb15">
              <div class="flexdr flexSB">
                <div
                  class="group-title-1 required"
                  style="padding: 0; align-items: center"
                >
                  {{ lang_data == 1 ? "项目" : "Product/Service" }}
                </div>
                <el-button
                  @click="addProject"
                  type="primary"
                  round
                  size="small"
                  icon="el-icon-circle-plus-outline"
                  >{{
                    lang_data == 1 ? "添加项目" : "Add the project"
                  }}</el-button
                >
              </div>
              <div class="project-table-box">
                <el-table
                  :data="form.project_list || []"
                  style="margin-top: 10px"
                  row-class-name="item-row"
                  class="itemTable"
                  stripe
                  header-row-class-name="item-table"
                  border
                >
                  <el-table-column
                    prop="project_name"
                    :label="lang_data == 1 ? '项目名称' : 'Item'"
                  ></el-table-column>
                  <el-table-column
                    :label="
                      lang_data == 1
                        ? '牙位/上下颌'
                        : 'Tooth bit / On the lower jaw'
                    "
                    :width="toothStates == 3 ? '400px' : '250px'"
                  >
                    <template slot-scope="scope">
                      <template v-if="scope.row.up_down_tooth.length > 0">
                        {{
                          scope.row.up_down_tooth
                            .map((val) => {
                              return {
                                1: lang_data == 1 ? "上颌" : "Upper jaw",
                                2: lang_data == 1 ? "下颌" : "The jaw",
                              }[val];
                            })
                            .join("、")
                        }}
                      </template>
                      <template v-else>
                        <ToothPosition
                          type="preview"
                          :tooth_top_left="scope.row.tooth_top_left"
                          :tooth_top_right="scope.row.tooth_top_right"
                          :tooth_bottom_left="scope.row.tooth_bottom_left"
                          :tooth_bottom_right="scope.row.tooth_bottom_right"
                          :toothStates="toothStates"
                        ></ToothPosition>
                      </template>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="up_down_tooth" label="上下颌">
                <template slot-scope="scope">
                  {{
                    scope.row.up_down_tooth
                      .map((val) => {
                        return { 1: "上颌", 2: "下颌" }[val];
                      })
                      .join("、")
                  }}
                </template>
              </el-table-column> -->
                  <el-table-column
                    prop="quantity"
                    :label="lang_data == 1 ? '数量' : 'Quantity'"
                    width="100"
                  ></el-table-column>

                  <el-table-column
                    prop="category_1_name"
                    :label="lang_data == 1 ? '项目类型(大类)' : 'Category'"
                    width="200"
                  ></el-table-column>
                  <el-table-column
                    width="90px"
                    :label="lang_data == 1 ? '操作' : 'Action'"
                  >
                    <template slot-scope="scope">
                      <el-link
                        class="mr10"
                        type="primary"
                        @click="editProject(scope.row, scope.$index)"
                        >{{ lang_data == 1 ? "编辑" : "edit" }}</el-link
                      >

                      <el-link
                        @click="removeProject(scope.$index)"
                        type="danger"
                        >{{ lang_data == 1 ? "删除" : "Delete" }}</el-link
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="group-title">
              {{ lang_data == 1 ? "要求" : "Instruction" }}
            </div>
            <el-input
              v-model="form.requirement"
              type="textarea"
              :rows="5"
            ></el-input>

            <div class="group-title">
              {{ lang_data == 1 ? "提交牙模" : "Files or Models" }}
            </div>

            <div style="margin-top: 10px">
              <el-radio-group v-model="form.submit_type" class="mb20">
                <el-radio :label="1">{{
                  lang_data == 1 ? "快递" : "Ship Models"
                }}</el-radio>
                <el-radio :label="2">{{
                  lang_data == 1 ? "口扫数据" : "Intra-Oral Scanner"
                }}</el-radio>
              </el-radio-group>
              <div
                v-if="form.submit_type == 1"
                style="width: 400px"
                label-width="100px"
              >
                <el-form-item
                  :label="lang_data == 1 ? '快递公司：' : 'Courier Company'"
                  size="mini"
                >
                  <ExpressCompanySelect
                    v-model="form.stl_express_company"
                    style="width: 280px"
                    :is-all="false"
                  ></ExpressCompanySelect>
                </el-form-item>
                <el-form-item
                  :label="lang_data == 1 ? '快递单号：' : 'Single Number'"
                >
                  <el-input
                    v-model="form.stl_express_no"
                    size="mini"
                    :placeholder="lang_data == 1 ? '请输入' : 'Please Enter'"
                    style="width: 280px"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <div v-else-if="form.submit_type == 2" class="port-scanning-row">
                <input
                  type="file"
                  hidden
                  ref="stl_upload_up"
                  @change="onSelectStl(1, $event)"
                />
                <el-form-item
                  :label="lang_data == 1 ? '上颌口扫' : 'Maxillary mouth scan'"
                >
                  <el-input
                    :placeholder="
                      lang_data == 1
                        ? '请选择口扫数据文件'
                        : 'Please select oral scan'
                    "
                    :value="stlFile_up ? stlFile_up.name : form.stl_file_up"
                    style="width: 300px"
                    size="mini"
                    readonly
                  ></el-input>
                  <el-button
                    size="mini"
                    class="ml10"
                    type="primary"
                    @click="$refs.stl_upload_up.click()"
                  >
                    {{ lang_data == 1 ? "上传" : "Upload" }}</el-button
                  >
                  <el-button
                    size="mini"
                    class="ml10"
                    @click="removeFile(1)"
                    type="danger"
                    >{{ lang_data == 1 ? "删除" : "Delete" }}</el-button
                  >
                  <!-- <span class="line-button fr"
              @click="$refs.stl_upload.click()">上传STL文件</span> -->
                </el-form-item>
                <input
                  type="file"
                  hidden
                  ref="stl_upload_down"
                  @change="onSelectStl(2, $event)"
                />
                <el-form-item :label="lang_data == 1 ? '下颌口扫' : 'Jaw scan'">
                  <el-input
                    :placeholder="
                      lang_data == 1
                        ? '请选择口扫数据文件'
                        : 'Please select oral scan'
                    "
                    :value="
                      stlFile_down ? stlFile_down.name : form.stl_file_down
                    "
                    style="width: 300px"
                    size="mini"
                    readonly
                  ></el-input>
                  <el-button
                    size="mini"
                    class="ml10"
                    type="primary"
                    @click="$refs.stl_upload_down.click()"
                    >{{ lang_data == 1 ? "上传" : "Upload" }}</el-button
                  >
                  <el-button
                    size="mini"
                    class="ml10"
                    @click="removeFile(2)"
                    type="danger"
                    >{{ lang_data == 1 ? "删除" : "Delete" }}</el-button
                  >
                  <!-- <span class="line-button fr"
              @click="$refs.stl_upload.click()">上传STL文件</span> -->
                </el-form-item>
                <input
                  type="file"
                  hidden
                  ref="stl_upload_bite"
                  @change="onSelectStl(3, $event)"
                />
                <el-form-item
                  :label="lang_data == 1 ? '咬合口扫' : 'Bite scan'"
                >
                  <el-input
                    :placeholder="
                      lang_data == 1
                        ? '请选择口扫数据文件'
                        : 'Please select oral scan'
                    "
                    :value="
                      stlFile_bite ? stlFile_bite.name : form.stl_file_bite
                    "
                    style="width: 300px"
                    size="mini"
                    readonly
                  ></el-input>
                  <el-button
                    size="mini"
                    class="ml10"
                    type="primary"
                    @click="$refs.stl_upload_bite.click()"
                    >{{ lang_data == 1 ? "上传" : "Upload" }}</el-button
                  >
                  <el-button
                    size="mini"
                    class="ml10"
                    @click="removeFile(3)"
                    type="danger"
                    >{{ lang_data == 1 ? "删除" : "Delete" }}</el-button
                  >
                  <!-- <span class="line-button fr"
              @click="$refs.stl_upload.click()">上传STL文件</span> -->
                </el-form-item>
                <el-divider content-position="left">{{
                  lang_data == 1
                    ? "以下为第三方平台获取口扫"
                    : "The following are third-party mouth scans"
                }}</el-divider>
                <el-form-item
                  :label="lang_data == 1 ? '第三方口扫：' : 'Third party'"
                >
                  <el-select
                    v-model="form.thirdParty"
                    :placeholder="lang_data == 1 ? '请选择' : 'Please choose'"
                    style="width: 300px"
                    size="mini"
                  >
                    <el-option
                      :label="lang_data == 1 ? '频泰' : 'PinTai'"
                      :value="1"
                    >
                    </el-option>
                    <el-option
                      :label="lang_data == 1 ? '先临' : 'XianLin'"
                      :value="2"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  :label="lang_data == 1 ? '案例名称：' : 'Case name'"
                >
                  <el-input
                    v-model.trim="form.caseName"
                    :placeholder="
                      lang_data == 1
                        ? '请输入 “2022-01-01_134154-张三” 查询'
                        : 'Please Enter'
                    "
                    style="width: 300px"
                    size="mini"
                  ></el-input>
                  <el-button
                    type="primary"
                    style="margin-left: 10px"
                    size="mini"
                    @click="caseBlur"
                  >
                    {{ lang_data == 1 ? "查询" : "Inquire" }}</el-button
                  >
                  <el-button
                    type="danger"
                    style="margin-left: 10px"
                    @click="removeFile(4)"
                    size="mini"
                    >{{ lang_data == 1 ? "删除" : "Delete" }}</el-button
                  >
                </el-form-item>
                <el-form-item
                  :label="lang_data == 1 ? '案例地址：' : 'Case address'"
                >
                  <el-input
                    v-model="form.caseObj.url"
                    size="mini"
                    :placeholder="
                      lang_data == 1 ? '查询案例名称后显示' : 'Show after query'
                    "
                    style="width: 300px; border: none"
                    readonly
                  ></el-input>
                </el-form-item>
              </div>
            </div>

            <div v-show="isShowAccessories">
              <div class="group-title">
                {{ lang_data == 1 ? "随单配件" : "Enclosed items" }}
              </div>

              <div>
                <OrderAccessories
                  ref="orderAccessories"
                  v-if="form.factory_id"
                  :factoryId="form.factory_id"
                  v-model="form.accessories_material"
                  @listChange="onOrderAccessoriesListChange"
                  @change="onOrderAccessoriesChange"
                ></OrderAccessories>
                <span v-else style="color: #999">{{
                  lang_data == 1
                    ? "请先选择工厂"
                    : "Please select a factory first"
                }}</span>
              </div>
            </div>
            <div v-if="craftList.length > 0">
              <div class="group-title">
                {{ lang_data == 1 ? "加工工艺" : "Processing technology" }}
              </div>
              <div style="overflow: hidden">
                <div
                  class="radio"
                  v-for="(items, index) in craftList"
                  :key="index"
                >
                  <div style="height: 40px; line-height: 40px">
                    {{ items.name
                    }}<el-button
                      v-if="form.process_technic_ids[index]"
                      icon="el-icon-circle-close"
                      type="text"
                      :title="
                        lang_data == 1
                          ? '清空当前选择'
                          : 'Clear current selection'
                      "
                      @click="clearProcessTechnicSelect(index)"
                    ></el-button>
                  </div>
                  <el-radio-group v-model="form.process_technic_ids[index]">
                    <el-radio
                      style="margin-top: 10px; width: 100%"
                      v-for="(item, i) in items.technics"
                      :key="i"
                      :label="item.process_technic_id"
                      ><span v-if="item.important == 1">※ </span
                      >{{ item.name }}
                      <img style="width: 14px" :src="item.icon" alt=""
                    /></el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>

            <div class="group-title mt10">
              {{ lang_data == 1 ? "收货地址" : "Castable Abutment"
              }}<span class="fs14 c999">{{
                lang_data == 1
                  ? "（说明：默认为诊所收货地址）"
                  : "（Note: The default is the clinic delivery address）"
              }}</span>
            </div>

            <div class="addressInfo">
              {{ form.receiver_name }}
              <span class="ml20">{{ form.receiver_mobile }}</span
              ><br />
              {{ form.receiver_address }}
            </div>
            <div
              class="group-title"
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              {{ lang_data == 1 ? "附件" : "Attachments" }}
              <el-button
                type="primary"
                round
                icon="el-icon-circle-plus-outline"
                size="small"
                @click="$refs.attach_upload.click()"
                >{{ lang_data == 1 ? "添加附件" : "Accessories" }}</el-button
              >
            </div>

            <input
              type="file"
              hidden
              ref="attach_upload"
              @change="onSelectAttach"
              multiple
            />
            <div v-for="(item, index) in form.attachment" :key="index">
              <span>{{ index + 1 }}. {{ item.name }}</span>
              <el-link
                class="ml20"
                type="danger"
                :title="lang_data == 1 ? '删除附件' : 'Delete'"
                @click="removeAttachFile(index, item.name)"
                >{{ lang_data == 1 ? "删除" : "Delete attachment" }}</el-link
              >
            </div>
            <AddProjectModal
              v-if="projectModal.show"
              :row="projectEdit.row"
              :factoryId="form.factory_id"
              :clinicId="form.clinic_id"
              @close="projectModal.show = false"
              @confirm="projectModal.onConfirm"
            ></AddProjectModal>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ClinicSelect,
  FactorySelect,
  ToothColorSelect,
  ExpressCompanySelect,
} from "@/components/select/index";
import AddProjectModal from "@/components/addProjectModal";
import ToothPosition from "@/components/toothPosition";
import AdminDoctorSelect from "@/components/select/admin-doctor-select.vue";
import OrderAccessories from "@/components/orderAccessories";
export default {
  name: "updateOrderModal",
  components: {
    ClinicSelect,
    FactorySelect,
    ToothColorSelect,
    ExpressCompanySelect,
    AddProjectModal,
    ToothPosition,
    AdminDoctorSelect,
    OrderAccessories,
  },
  props: {},
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      btnLoading: false,
      loaded: true,
      orderId: "",
      is_tmp: 1,
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      toothStates: "",
      form: {
        order_type: "",
        box_no: "",
        clinic_id: "",
        is_admin: 0, // 当前登录用户是否是诊所管理员
        factory_id: "",
        doctor_id: "",
        doctor_mobile: "",
        temporary_no: "",
        patient_name: "",
        patient_age: "",
        express_promise_time: "",
        patient_gender: "",
        color_id: "",
        color_name: "", // 当color_id为-1其他时，手动填入的颜色值
        up_color_id: "",
        up_color_name: "",
        low_color_id: "",
        low_color_name: "",
        butment_color_id: "",
        butment_color_name: "",
        project_list: [], // {project_id,tooth_top_left,tooth_top_right,tooth_bottom_left,tooth_bottom_right,price,row_total,tooth_type上下颌}

        requirement: "", // 要求
        remark_inside: "", // 内部备注

        submit_type: null, // 牙模提价类型  1 快递 2 口扫数据
        // submit_type == 1
        stl_express_company: "SF", // 快递公司代码
        stl_express_no: "", // 快递单号
        // submit_type == 2
        stl_file: "", // 口扫数据文件地址

        attachment: [], // 附件 {id: '', name: '', url: '', file: null}

        receiver_name: "", // 收货人 诊所
        receiver_mobile: "", // 收货人手机 诊所
        receiver_address: "", // 收货人地址 诊所

        accessories_material: [], // 随单配件选中项
        process_technic_ids: [], // 加工工艺
        thirdParty: 1,
        caseName: "",
        caseObj: {},
      },

      rules: {
        factory_id: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "请选择工厂"
                : "Please select factory",
            trigger: "blur",
          },
        ],
        clinic_id: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "请选择诊所"
                : "Please select a clinic",
          },
        ],
        doctor_id: [
          {
            required: true,
            message:
              localStorage.lang_data == 1 ? "请选择医生" : "Choose your doctor",
          },
        ],
        patient_name: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "请输入患者姓名"
                : "Please enter the patient",
          },
        ],
        color_id: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "请选择牙齿颜色"
                : "Please select a tooth color",
            trigger: "blur",
          },
        ],
      },

      // 用作上传临时保存
      stlFile: null, // stl文件
      stlFile_down: null,
      stlFile_up: null,
      stlFile_bite: null,
      projectModal: {
        show: false,
        onConfirm: () => {},
      },
      scrollTop: 0,
      doctorInfo: {},
      loading: true,
      projectEdit: {},

      isShowAccessories: false,
      craftList: [], //加工工艺列表
      progressList: ["", "", "", ""],
    };
  },
  watch: {
    "$store.state.progress"(val) {
      let data = JSON.parse(JSON.stringify(this.progressList));
      data.forEach((item, i) => {
        if (item.name == val.name && item.size == val.size) {
          this.progressList[i] = val;
          this.$set(
            this.progressList[i],
            "progressVal",
            Math.round(Number(val.progress) * 100)
          );
          this.$forceUpdate();
        }
      });
    },
  },
  computed: {
    isEdit() {
      return !!this.orderId;
    },
    // 当前医生是否是管理员
    // isAdminUser() {
    // let userData = localStorage.userData
    //   ? JSON.parse(localStorage.userData)
    //   : {};
    // return userData.is_admin > 0;

    // },
    totalPrice() {
      let total = 0;
      let project_list = this.form.project_list || [];
      project_list.forEach((item) => {
        let row_total = this.getRowTotal(item);
        total += row_total;
      });
      return total;
    },
  },
  async created() {
    await this.getToothStates();
    this.orderId = this.$route.query.id;
    this.is_tmp = this.$route.query.type || 1;
    if (this.isEdit) {
      await this.getDetail();
    } else {
      if (localStorage.userData) {
        this.doctorInfo = JSON.parse(localStorage.userData);
        this.form.doctor_id = this.doctorInfo.id;
        this.form.clinic_id = this.doctorInfo.clinic_id;
        if (!this.$route.query.id) {
          this.createOrderInit();
        }
      }
      this.loaded = true;
    }
    window.addEventListener("scroll", this.setTopBar);
  },

  methods: {
    async getToothStates() {
      return this.$axios.post("/ucenter/getToothShowType").then((res) => {
        if (res.code != 1) {
          this.$message.error(res.msg);
          return;
        } else {
          this.toothStates = res.data;
        }
      });
    },
    caseBlur() {
      this.$axios
        .post("/orders/orders/getPortScanning", {
          type: this.form.thirdParty,
          name: this.form.caseName,
        })
        .then((res) => {
          if (res.code == 1) {
            this.form.caseObj = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    removeFile(val) {
      if (val == 1) {
        this.stlFile_up = null;
        this.form.stl_file_up = null;
      } else if (val == 2) {
        this.stlFile_down = null;
        this.form.stl_file_down = null;
      } else if (val == 3) {
        this.stlFile_bite = null;
        this.form.stl_file_bite = null;
      } else if (val == 4) {
        this.form.caseObj = {};
        this.form.caseName = "";
      }
    },
    clearProcessTechnicSelect(index) {
      this.$set(this.form.process_technic_ids, index, "");
    },
    async processingTechnology() {
      let res = await this.$axios.post("/orders/orders/getProcessTechnicList", {
        factory_id: this.form.factory_id,
      });

      if (res.code == 1) {
        this.craftList = res.data.list;
        this.loading = false;
      } else {
        this.$message.error(res.msg);
        this.loading = false;
      }
    },
    onAdminDoctorChange(val, option) {
      this.doctorInfo = {
        id: option.doctor_id,
        true_name: option.doctor_name,
        mobile: option.mobile,
      };
    },
    async createOrderInit() {
      let data = await this.getClinicInfo({
        id: this.form.clinic_id,
      });
      let recipient_address = data.recipient_address;
      this.form.receiver_name = recipient_address.accept_name;
      this.form.receiver_mobile = recipient_address.tel;
      this.form.receiver_address =
        recipient_address.province.value +
        recipient_address.city.value +
        recipient_address.county.value +
        recipient_address.addr;
    },
    setTopBar() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
    getClinicInfo() {
      // 获取诊所详情
      let params = {
        id: this.form.clinic_id,
      };
      return new Promise((resolve) => {
        this.$axios
          .get("/setting/clinic/getclinicdetail", { params })
          .then((res) => {
            if (res.code != 1) {
              this.$message.error(res.msg);
              return;
            } else {
              resolve(res.data);
            }
          });
      });
    },
    async getDetail() {
      this.loading = true;
      let res = await this.$axios.post("/orders/orders/getDetail", {
        id: this.orderId,
      });
      if (res.code == 1) {
        let data = res.data;
        this.is_tmp = data.is_tmp_storage;
        this.form = {
          clinic_id: data.clinic_id,
          is_admin: 0,
          doctor_id: data.doctor_id,
          temporary_no: data.temporary_no,
          factory_id: data.factory_id,
          patient_name: data.patient_name,
          patient_age: data.patient_age ? data.patient_age : "",
          express_promise_time: data.express_promise_time
            ? data.express_promise_time * 1000
            : null,
          patient_gender: data.patient_gender,
          up_color_id: data.up_color_id,
          up_color_name: data.up_color_id == -1 ? data.up_color_name : "",
          color_id: data.color_id,
          color_name: data.color_id == -1 ? data.color_name : "",
          low_color_id: data.low_color_id,
          low_color_name: data.low_color_id == -1 ? data.low_color_name : "",

          butment_color_id: data.butment_color_id,
          butment_color_name:
            data.butment_color_id == -1 ? data.butment_color_name : "",

          project_list: data.project_list.map((item) => {
            return {
              project_id: item.project_id,
              project_name: item.project_name,
              input_type: item.input_type,
              // 牙位
              tooth_top_left: item.tooth_top_left,
              tooth_top_right: item.tooth_top_right,
              tooth_bottom_left: item.tooth_bottom_left,
              tooth_bottom_right: item.tooth_bottom_right,
              // 上下颌
              up_down_tooth: item.tooth_type ? item.tooth_type.split(",") : [],

              price: item.price,
              quantity: item.quantity,

              category_1_id: item.category_1_id,
              category_1_name: item.category_1_name,
              attributesList: item.attribute,
              id: item.id,
            };
          }), // {project_id,tooth_top_left,tooth_top_right,tooth_bottom_left,tooth_bottom_right,price,row_total,tooth_type上下颌}

          requirement: data.requirement, // 要求
          remark_inside: data.remark_inside, // 内部备注

          submit_type: data.submit_type, // 牙模提价类型  1 快递 2 口扫数据
          // submit_type == 1
          stl_express_company: data.stl_express_company, // 快递公司代码
          stl_express_no: data.stl_express_no, // 快递单号
          // submit_type == 2
          stl_file_down: data.stl_file_down, // 口扫数据文件地址
          stl_file_up: data.stl_file_up, // 口扫数据文件地址
          stl_file_bite: data.stl_file_bite, // 口扫数据文件地址

          attachment: data.attachment || [], // 附件 {id: '', name: '', url: '', file: null}

          receiver_name: data.receiver_name, // 收货人 诊所
          receiver_mobile: data.receiver_mobile, // 收货人手机 诊所
          receiver_address: data.receiver_address, // 收货人地址 诊所

          accessories_material: data.accessories_material, // 随单配件选中项
          // process_technic_ids: data.process_technics.map((items) => {
          //   return items.technics[0].process_technic_id;
          // }), // 加工工艺
          process_technic_ids: [],
          thirdParty: data.order_port_scanning
            ? data.order_port_scanning.type_show.value
            : 1,
          caseName: data.order_port_scanning
            ? data.order_port_scanning.name
            : "",
          caseObj: data.order_port_scanning ? data.order_port_scanning : {},
        };
        this.doctorInfo = {
          id: data.doctor_id,
          true_name: data.doctor_name,
          mobile: data.doctor_mobile,
          clinic_id: data.clinic_id,
        };

        this.loaded = true;

        this.$nextTick(async () => {
          this.$refs.orderAccessories.setData();
          if (this.isEdit) {
            await this.processingTechnology();
          }

          // 两个数组合并
          if (this.craftList.length > 0 && data.process_technics.length > 0) {
            let craftList = JSON.parse(JSON.stringify(this.craftList));
            let craftActiveList = data.process_technics;

            // 如果当前订单存在列表中不存在的工艺类型，将其加入到列表中
            craftActiveList.forEach((activeItem) => {
              let idx = craftList.findIndex((li) => li.id == activeItem.id);
              if (idx == -1) {
                craftList.push(activeItem);
              }
            });

            craftList.forEach((item) => {
              let selected = craftActiveList.find(
                (item1) => item.id == item1.id
              );
              if (selected) {
                // 分类相同
                let tlist = [];
                item.technics.forEach((tItem) => {
                  let idx = tlist.findIndex(
                    (tli) => tli.process_technic_id == tItem.process_technic_id
                  );
                  if (idx == -1) {
                    tlist.push(tItem);
                  }
                });
                if (selected.technics.length > 0) {
                  let idx = tlist.findIndex(
                    (tli) =>
                      tli.process_technic_id ==
                      selected.technics[0].process_technic_id
                  );
                  if (idx == -1) {
                    tlist.push(selected.technics[0]);
                  }
                }
                item.technics = tlist;
              }
            });
            this.craftList = craftList;
            this.form.process_technic_ids = craftList.map((item) => {
              let selected = craftActiveList.find(
                (item1) => item.id == item1.id
              );
              if (selected) {
                let idx = item.technics.findIndex(
                  (i) =>
                    selected.technics.findIndex(
                      (j) => i.process_technics_id == j.process_technics_id
                    ) !== -1
                );
                return idx == -1 ? "" : selected.technics[0].process_technic_id;
              }
              return "";
            });
            // this.craftList.forEach((item, i) => {
            //   item.technics.push(data.process_technics[i].technics[0]);
            // });
            // // 去重
            // let arr = JSON.parse(JSON.stringify(this.craftList));
            // arr.forEach((item) => {
            //   item.technics = this.unique(item.technics);
            // });
            // // 赋值
            // this.craftList = arr;
            // this.loading = false;
          } else {
            // this.loading = false;
          }
          setTimeout(() => {
            this.loading = false;
          }, 300);
        });
      } else {
        this.$message.error(res.msg);
        this.loading = false;
      }
    },
    // 数组去重方法
    unique(arr) {
      const res = new Map();
      return arr.filter(
        (a) =>
          !res.has(a.process_technic_id) && res.set(a.process_technic_id, 1)
      );
    },
    defaultChang(val) {
      this.form.factory_id = val;
      if (!this.isEdit) {
        this.onFactoryChange();
      }
    },
    onFactoryChange() {
      this.form.project_list = [];
      this.form.up_color_id = "";
      this.form.up_color_name = "";
      this.form.color_id = "";
      this.form.color_name = "";
      this.form.low_color_id = "";
      this.form.low_color_name = "";
      this.form.butment_color_id = "";
      this.form.butment_color_name = "";
      this.form.accessories_material = [];
      this.form.express_promise_time = "";
      this.$nextTick(() => {
        this.$refs.orderAccessories.setData();
        this.processingTechnology();
      });
    },
    // 编辑初始化诊所
    onClinicInit(val, option) {
      this.form.is_admin = option.is_admin;
      // 此处需要将doctor_info也初始化一下
    },
    // 手动切换诊所
    async onClinicChange(val, option) {
      if (this.form.is_admin > 0) {
        this.doctorInfo.true_name = JSON.parse(localStorage.userData).true_name;
        this.doctorInfo.mobile = JSON.parse(localStorage.userData).mobile;
      }
      this.form.doctor_id = JSON.parse(localStorage.userData).doctor_id;
      this.form.doctor_mobile = JSON.parse(localStorage.userData).mobile;

      this.form.factory_id = "";
      this.form.project_list = [];
      this.form.up_color_id = "";
      this.form.up_color_name = "";
      this.form.color_id = "";
      this.form.color_name = "";
      this.form.low_color_id = "";
      this.form.low_color_name = "";
      this.form.butment_color_id = "";
      this.form.butment_color_name = "";
      this.form.is_admin = option.is_admin;
      let data = await this.getClinicInfo({
        id: val,
      });
      let recipient_address = data.recipient_address;
      this.form.receiver_name = recipient_address.accept_name;
      this.form.receiver_mobile = recipient_address.tel;
      this.form.receiver_address =
        recipient_address.province.value +
        recipient_address.city.value +
        recipient_address.county.value +
        recipient_address.addr;
    },
    // 当工厂个未配置随单配件时
    onOrderAccessoriesListChange(list) {
      if (list.length == 0) {
        this.isShowAccessories = false;
      } else {
        this.isShowAccessories = true;
      }
    },
    onOrderAccessoriesChange(list) {
      this.form.accessories_material = list;
    },

    addProject() {
      if (!this.form.factory_id) {
        this.$message.warning(
          this.lang_data == 1 ? "请先选择工厂" : "Please select a factory first"
        );
        return;
      }
      this.projectEdit = {};
      this.projectModal = {
        show: true,
        onConfirm: (data) => {
          if (
            this.form.project_list.filter(
              (item) => item.project_id == data.project.id
            ).length > 0
          ) {
            //判断项目是否重复
            this.$message.warning(
              this.lang_data == 1
                ? `[ ${data.project.name} ]已存在！`
                : `[ ${data.project.name} ] Existing！`
            );
            return;
          }

          this.form.project_list.push({
            project_id: data.project.id,
            project_name: data.project.name,
            input_type: data.project.input_type,
            // 牙位
            tooth_top_left: data.tooth_top_left,
            tooth_top_right: data.tooth_top_right,
            tooth_bottom_left: data.tooth_bottom_left,
            tooth_bottom_right: data.tooth_bottom_right,
            // 上下颌
            up_down_tooth: data.up_down_tooth,

            price: "",
            quantity: data.quantity,

            category_1_id: data.project.category_1_id,
            category_1_name: data.project.category_1_name,
            attributesList: data.attributesList,
          });

          this.projectModal.show = false;
        },
      };
    },
    getRowTotal(row) {
      let price = row.price * 1 || 0;
      let count = row.quantity;
      return price * count;
    },
    removeProject(index) {
      this.form.project_list.splice(index, 1);
    },
    editProject(row, index) {
      this.projectEdit = { index: index, row: row };
      if (!this.form.factory_id) {
        this.$message.warning(
          this.lang_data == 1 ? "请先选择工厂" : "Please select a factory first"
        );
        return;
      }
      this.projectModal = {
        show: true,
        onConfirm: (data) => {
          if (
            this.form.project_list.filter(
              (item) => item.project_id == data.project.value
            ).length > 0
          ) {
            if (data.project.value != this.projectEdit.row.project_id) {
              //判断项目是否重复
              this.$message.warning(
                this.lang_data == 1
                  ? `[ ${data.project.name} ]已存在！`
                  : `[ ${data.project.name} ] Existing！`
              );
              return;
            }
          }
          this.form.project_list.splice(this.projectEdit.index, 1);
          this.form.project_list.splice([this.projectEdit.index], 0, {
            id: data.id,
            project_id: data.project_id,
            project_name: data.project.name,
            input_type: data.project.input_type,
            // 牙位
            tooth_top_left: data.tooth_top_left,
            tooth_top_right: data.tooth_top_right,
            tooth_bottom_left: data.tooth_bottom_left,
            tooth_bottom_right: data.tooth_bottom_right,
            // 上下颌
            up_down_tooth: data.up_down_tooth,

            price: "",
            quantity: data.quantity,

            category_1_id: data.project.category_1_id,
            category_1_name: data.project.category_1_name,
            attributesList: data.attributesList,
          });
          this.projectModal.show = false;
          this.projectEdit = {};
        },
      };
    },
    // 选择口扫数据文件
    onSelectStl(val, e) {
      let files = e.target.files;
      if (files.length == 0) return;
      if (val == 1) {
        this.stlFile_up = files[0];
        this.progressList[0] = {
          name: files[0].name,
          size: files[0].size,
        };
      } else if (val == 2) {
        this.stlFile_down = files[0];
        this.progressList[1] = {
          name: files[0].name,
          size: files[0].size,
        };
      } else if (val == 3) {
        this.stlFile_bite = files[0];
        this.progressList[2] = {
          name: files[0].name,
          size: files[0].size,
        };
      }
      e.target.value = "";
    },
    // 选择附件
    onSelectAttach(e) {
      let files = e.target.files;
      if (files.length == 0) return;

      for (let i = 0; i < files.length; i++) {
        this.form.attachment.push({
          name: files[i].name,
          url: "",
          file: files[i],
        });
      }
      this.progressList.push({
        name: files[0].name,
        size: files[0].size,
      });
      e.target.value = "";
    },
    // 移除附件
    removeAttachFile(index, name) {
      this.form.attachment.splice(index, 1);
      let index1 = this.progressList.findIndex((item) => item.name == name);
      if (index1 > -1) {
        this.progressList.splice(index1, 1);
      }
    },
    uploadAttachFiles(files) {
      let list = [];
      files.forEach(async (item) => {
        // 未上传
        if (item.file) {
          list.push(
            // eslint-disable-next-line no-async-promise-executor
            new Promise(async (resolve) => {
              this.btnLoading = true;
              let url = await this.upload(item.file);
              item.url = url;
              item.file = null;
              resolve();
            })
          );
        }
      });
      return Promise.all(list);
    },
    upload(file) {
      return new Promise((resolve) => {
        this.$getuploadsign(4, file).then((res) => {
          if (res.res.code != 1) {
            this.$message(res.res.msg);
            return;
          }
          resolve(res.url);
        });
      });
    },
    getFormatDate(date) {
      if (!date) return "";
      let start = !date ? "" : this.$moment(date).format("YYYY-MM-DD 00:00:00");
      return start;
    },
    onConfirm(type) {
      // this.$emit("success");
      this.$refs.form.validate(async (val) => {
        if (val) {
          if (this.form.project_list.length == 0) {
            this.$message.warning(
              this.lang_data == 1 ? "请添加项目" : "Please add items"
            );
            return;
          }
          if (this.form.color_id == -1 && this.form.color_name == "") {
            this.$message.warning(
              this.lang_data == 1
                ? "请填写体部牙齿其他颜色"
                : "Please fill in other tooth colors"
            );
            return;
          }
          // if (this.form.submit_type == 1) {
          //   if (this.form.stl_express_company == "") {
          //     this.$message.warning("请选择快递公司");
          //     return;
          //   }
          //   if (this.form.stl_express_no == "") {
          //     this.$message.warning("请填写快递单号");
          //     return;
          //   }
          // } else

          this.loading = true;

          if (!this.form.submit_type) {
            this.$message.warning(
              this.lang_data == 1
                ? "请选择牙模"
                : "Clear the choice of dental mold"
            );
            return;
          } else if (this.form.submit_type == 2) {
            if (this.stlFile_up) {
              this.btnLoading = true;
              this.form.stl_file_up = await this.upload(this.stlFile_up); // 下颌文件地址
            }
            if (this.stlFile_down) {
              this.btnLoading = true;
              this.form.stl_file_down = await this.upload(this.stlFile_down); // 上颌文件地址
            }
            if (this.stlFile_bite) {
              this.btnLoading = true;
              this.form.stl_file_bite = await this.upload(this.stlFile_bite); // 咬合数据文件地址
            }
          }

          let data = {
            factory_id: this.form.factory_id,
            clinic_id: this.form.clinic_id,
            doctor_id: this.form.doctor_id,
            order_type: 1,
            // doctor_mobile: this.form.doctor_mobile,
            patient_name: this.form.patient_name,
            patient_age: this.form.patient_age,
            temporary_no: this.form.temporary_no,
            express_promise_time: this.form.express_promise_time
              ? this.getFormatDate(this.form.express_promise_time)
              : "",
            patient_gender: this.form.patient_gender,
            up_color_id: this.form.up_color_id,
            up_color_name: this.form.up_color_name,
            color_id: this.form.color_id,
            color_name: this.form.color_name,
            low_color_id: this.form.low_color_id,
            low_color_name: this.form.low_color_name,
            butment_color_id: this.form.butment_color_id,
            butment_color_name: this.form.butment_color_name,
            is_tmp_storage: type == 1 ? type : -1,

            project_list: this.form.project_list.map((item) => {
              return {
                project_id: item.project_id,
                // project_name: data.project.name,
                // input_type: data.project.input_type,
                // 牙位
                tooth_top_left: item.tooth_top_left,
                tooth_top_right: item.tooth_top_right,
                tooth_bottom_left: item.tooth_bottom_left,
                tooth_bottom_right: item.tooth_bottom_right,
                // 上下颌
                tooth_type: item.up_down_tooth,

                quantity: item.quantity,
                attribute: JSON.stringify(item.attributesList),

                // category_1_id: data.project.category_1_id,
                // category_1_name: data.project.category_1_name
              };
            }), // {project_id,tooth_top_left,tooth_top_right,tooth_bottom_left,tooth_bottom_right,price,row_total,tooth_type上下颌}

            requirement: this.form.requirement, // 要求

            submit_type: this.form.submit_type, // 牙模提价类型  1 快递 2 口扫数据

            accessories_material: this.form.accessories_material,
            process_technic_ids: this.form.process_technic_ids,
          };

          //-------------提交牙模-------------
          if (this.form.submit_type == 1) {
            // submit_type == 1
            (data.stl_express_company = this.form.stl_express_no
              ? this.form.stl_express_company
              : ""), // 快递公司代码
              (data.stl_express_no = this.form.stl_express_no); // 快递单号
          } else if (this.form.submit_type == 2) {
            // submit_type == 2 上传stl数据文件
            data.stl_file_up = this.form.stl_file_up;
            data.stl_file_down = this.form.stl_file_down;
            data.stl_file_bite = this.form.stl_file_bite;
            data.port_scanning_id = this.form.caseObj.id || "";
          }
          //-------------上传附件-------------
          data.attachment = this.form.attachment; // 附件 {id: '', name: '', url: '', file: null}

          await this.uploadAttachFiles(data.attachment);

          if (data.clinic_id) {
            data.receiver_name = this.form.receiver_name; // 收货人 诊所
            data.receiver_mobile = this.form.receiver_mobile; // 收货人手机 诊所
            data.receiver_address = this.form.receiver_address; // 收货人地址 诊所
          }

          if (this.isEdit) {
            data.id = this.orderId;
          }

          let res = await this.$axios.post("/orders/orders/create", data);

          if (res.code == 1) {
            this.$message.success(res.msg);
            this.$emit("success", res.data.id);

            this.btnLoading = false;
            this.loading = false;

            this.stlFile_up = false;
            this.stlFile_down = false;
            this.stlFile_bite = false;

            setTimeout(() => {
              this.$router.push("/home/");
            }, 200);
          } else {
            this.$message.error(res.msg);
            this.btnLoading = false;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#wrap .createOrder._full {
  height: 100%;
  display: table;
}
.posFix {
  position: fixed;
  left: 0;
  right: 0;
  height: 60px !important;
}
.scrollClass {
  margin-top: 80px;
}
.createOrder {
  width: 100%;
  height: calc(100% - 70px);
  overflow: auto;
  background: #fff;
  .content {
    width: 1300px;

    margin: 20px auto 30px;
    position: relative;
    .progress {
      position: absolute;
      width: 400px;
      overflow: hidden;
      right: 50px;
      top: 100px;
      background: #f8f8f8;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 0 10px 2px #999;
      z-index: 10000;
      flex-direction: column;
      .progressCon {
        width: 100%;
        margin: 0 auto;
        .progressName {
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    .topbar {
      height: 60px;
      padding: 0 36px;
      background: #f4f0f2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // width: 808px;
      z-index: 10;
      top: 0;
      transition: all 0.5s;
    }
  }
}
.mainform {
  padding: 30px 68px 50px;
  min-height: 300px;
  .addItembtn {
    color: #409eff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
  .radio {
    width: 175px;
    height: 100%;
    float: left;
    border-left: 1px solid #e6e6e6;
    padding: 0 5px 0 10px;
    overflow: hidden;
  }
}

.group-title,
.group-title-1 {
  font-weight: bold;
  padding: 15px 0;
  color: #333;
  &.required:before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  span {
    font-weight: normal;
  }
}
.group-title-1 {
  padding-top: 10px;
}
.addressInfo {
  background: #f7f8f9;
  color: #333333;
  padding: 10px 14px;
  line-height: 25px;
}

.icon-personal-add {
  margin: auto 5px;
  width: 22px;
  height: 22px;
  display: inline-block;
  background-image: url("/static/img/addico.png");
  background-size: 100% 100%;
}
.el-form-item__error {
  padding-top: 0px;
}
</style>
<style lang="scss">
.createOrder {
  .mainform {
    .el-form-item {
      margin-bottom: 5px;
    }
    .el-form-item__error {
      padding-top: 0;
      top: 37px;
    }
    .el-radio__label {
      white-space: initial;
      word-break: break-all;
    }
  }
}
</style>
