<template>
  <div class="order-accessories-group">
    <div
      class="order-accessories-item"
      v-for="(item, index) in list"
      :key="index"
    >
      <el-checkbox
        v-model="item.checked"
        size="mini"
        style="width: 80px"
        @change="onDataChange"
        >{{ item.name }}</el-checkbox
      >
      <el-input
        v-if="item.type == 1"
        v-model="item.content"
        size="mini"
        style="width: 90px"
        :style="{ visibility: item.checked ? 'visible' : 'hidden' }"
        @change="onDataChange"
      ></el-input>
      <el-input-number
        v-else
        v-model="item.number"
        size="mini"
        style="width: 90px"
        :style="{ visibility: item.checked ? 'visible' : 'hidden' }"
        controls-position="right"
        :min="1"
        @change="onDataChange"
      ></el-input-number>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
    },
    factoryId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      sourceList: [],
      list: [], // 所有列表
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    setData() {
      let list = [];
      let outData = this.value || [];
      let sourceList = JSON.parse(JSON.stringify(this.sourceList));
      outData.forEach((ot) => {
        let idx1 = sourceList.findIndex(
          (i) => i.accessories_material_id == ot.accessories_material_id
        );
        if (idx1 == -1) {
          // 列表中不存在(可能被禁用)
          list.push({
            accessories_material_id: ot.accessories_material_id,
            checked: true,
            content: ot.content,
            name: ot.name,
            number: ot.number,
            type: ot.type,
          });
        } else {
          list.push({
            accessories_material_id: ot.accessories_material_id,
            checked: true,
            content: ot.content,
            name: ot.name,
            number: ot.number,
            type: ot.type,
          });
        }
      });
      // 剩余数据
      let leaveData = sourceList.filter(
        (item) =>
          list.findIndex(
            (li) => li.accessories_material_id == item.accessories_material_id
          ) == -1
      );
      leaveData.forEach((item) => {
        list.push({
          accessories_material_id: item.accessories_material_id,
          checked: false,
          content: "", //item.content,
          name: item.name,
          number: 1, //item.number,
          type: item.type,
        });
      });
      list.sort(
        (a, b) => a.accessories_material_id - b.accessories_material_id
      );
      this.list = list;
    },
    getList() {
      let params = {
        factory_id: this.factoryId,
        accessories_material_status: 1,
      };
      this.$axios
        .post("/orders/orders/getaccessoriesmateriallist", params)
        .then((res) => {
          if (res.code == 1) {
            this.sourceList = res.data.list.map(
              (item) => {
                return {
                  accessories_material_id: item.id,
                  name: item.name,
                  type: item.type,
                  number: 1,
                  content: "", // 其他时必填否则无需填
                  checked: false,
                };
              }
              // Object.assign({}, item, { checked: false, count: 1 })
            );
            this.$emit(
              "listChange",
              JSON.parse(JSON.stringify(this.sourceList))
            );
            this.setData();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    onDataChange() {
      let list = this.list.filter((item) => item.checked);

      this.$emit("change", JSON.parse(JSON.stringify(list)));
    },
  },
  watch: {
    factoryId() {
      this.getList();
    },
  },
};
</script>
<style lang="scss">
.order-accessories-group {
  display: flex;
  flex-wrap: wrap;
  .order-accessories-item {
    margin-right: 20px;
    width: 170px;
    .el-input {
      .el-input__inner {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    .el-input-number {
      .el-input__inner {
        padding-left: 5px;
        padding-right: 30px;
      }
    }
  }
}
</style>